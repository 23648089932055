import React from 'react';
import './index.css';
import Image from '../../img/about_1.jpg';

function AboutMission() {
    return (
        <div className="section" id="about-mission">
            <div className="left">
                <img src={ Image } alt=""/>
            </div>
            <div className="right">
                <h3>Our Mission</h3>
                <p>Since 2013, since Accurate was born, the technology was booming. The journey started with moderate employees and slowly into a reputable journey of delivering quality services to various firms. </p>
                <p>We have provided global solutions to companies who aspire to take their business to the next level, supporting them to establish a healthy and positive connectivity in the world market.</p>
            </div>
        </div>
    )
}

export default AboutMission;