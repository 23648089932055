import React from 'react';
import './index.css';
import {IoEarth} from 'react-icons/io5';
import {GiClick} from 'react-icons/gi';
import {HiOutlineLightBulb} from 'react-icons/hi';
import {BiCaretRightCircle} from 'react-icons/bi';
import {CgArrowsExpandLeftAlt} from 'react-icons/cg';
import {MdMoneyOff} from 'react-icons/md';

function AboutOdoo(){
    return (
        <div className="section" id="aboutodoo">
            <div className="left">
                <div className="box">
                    <GiClick />
                    <h4>Easy to Use</h4>
                    <p>Odoo is easy to set up, intuitive and the modules are well integrated.</p>
                </div>
                <div className="box">
                    <CgArrowsExpandLeftAlt />
                    <h4>Fluidity</h4>
                    <p>Odoo's fluidity feature cover the needs of any kind of companies.</p>
                </div>
                <div className="box">
                    <MdMoneyOff />
                    <h4>Zero Licence Fee</h4>
                    <p>Odoo is an open source program and you never want to pay a license fee.</p>
                </div>
                <div className="box">
                    <HiOutlineLightBulb />
                    <h4>Advanced</h4>
                    <p>Odoo uses Python which runs on major contemporary systems.</p>
                </div>
                
            </div>
            <div className="right">
                <h3>Why you'll love Odoo.</h3>
                <p>Odoo is a suite of open source business apps that cover all your company needs: CRM, eCommerce, accounting, inventory, point of sale, project management, etc.</p>
                <p>A smooth and friendly user's experience that has been built to ensure the user seamless adoption.</p>

                <ul>
                    <li> <BiCaretRightCircle /> Integrated apps</li>
                    <li> <BiCaretRightCircle /> Kept simple</li>
                    <li> <BiCaretRightCircle /> Loved by millions of happy users</li>
                </ul>
            </div>
        </div>
    )
}

export default AboutOdoo;