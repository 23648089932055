import React from 'react';
import './index.css';
import Logo from '../../img/logo_white.png';
import { FaAngleRight } from 'react-icons/fa';
import { Link } from 'react-router-dom';

function Footer() {
    return (
        <div id="footer">
            <div className="section">
                <div className="boxes">
                    <div className="logo-box"><img src={ Logo } alt="" className="logo"/></div>
                    <p>Accurate evolved with a vision to provide one-stop solutions that cater the needs of a business and to help them soar high.</p>
                    <div className="social">

                    </div>
                </div>

                <div className="boxes">
                    <h5>Helpful Links</h5>
                    <ul>
                        <li><Link to="/about"><FaAngleRight />About Us</Link></li>
                        <li><a href="#"><FaAngleRight />Features</a></li>
                        <li><a href="#"><FaAngleRight />FAQ's</a></li>
                        <li><a href="#"><FaAngleRight />Blog</a></li>
                    </ul>
                </div>

                <div className="boxes">
                    <h5>India</h5>
                    <div className="address">
                        <p>#303, 3rd floor, Mulakkampally complex, Kunnumpuram, Kakkanad, Kochi, India 682021 </p>
                        <p>Phone: +91 82899 58063</p>
                        <p><span>E-Mail: </span><a href="#">info@accurate.com</a></p>
                    </div>
                </div>

                <div className="boxes">
                    <h5>Saudi Arabia</h5>
                    <div className="address">
                        <p>Ittisalat Street, Dammam, Kingdom of Saudi Arabia</p>
                        <p>Phone: +966 59 473 6585</p>
                        <p><span>E-Mail: </span><a href="#">roshan@accurate.com</a></p>
                    </div>
                </div>
            </div>
            <p className="section copyright">© 2021 Accurate. All Rights Reserved.</p>
        </div>
    )
}

export default Footer;