import React from 'react';
import './index.css';
import { FaLocationArrow, FaPhoneAlt, FaPaperPlane} from 'react-icons/fa';

function ContactDetails(){
    return (
        <div id="contact-details">
           <div className="section">

               <div className="box">
                   <div className="icon"><FaLocationArrow /></div>
                   <h3>India</h3>
                   <p>#303, 3rd floor, Mulakkampally complex, Kakkanad, India 682021</p>
               </div>

               <div className="box">
                   <div className="icon"><FaLocationArrow /></div>
                   <h3>Saudi Arabia</h3>
                   <p>Ittisalat Street, <br/> Dammam, Kingdom of Saudi Arabia</p>
               </div>

               <div className="box">
                   <div className="icon"><FaPhoneAlt /></div>
                   <h3>Phone</h3>
                   <p>+91 82899 58063<br/>+966 59 473 6585<br/><br/></p>
               </div>

               <div className="box">
                   <div className="icon"><FaPaperPlane /></div>
                   <h3>Email</h3>
                   <p>info@accurate.com<br/>roshan@accurate.com <br/><br/></p>
               </div>

           </div>
        </div>
    )
}

export default ContactDetails;