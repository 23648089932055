import React from 'react';
import './index.css';
import logo from '../../img/accurate-rect.png';
import { FaAngleDown } from 'react-icons/fa';

import { BrowserRouter as Router, Route, Link } from "react-router-dom";

function toggleMenu(e){
    document.querySelector('.nav .right .mobile-menu .hamburger').classList.toggle('clicked');
    document.querySelector('.nav .right .mobile-menu ul').classList.toggle('active');
}

function NavBar( { items, fixed } ){
    return (
        <div className={ "nav fixed-" + fixed }>
            <div className="navbox">
                    <div className="left">
                        <img src={ logo } alt=""/>
                    </div>
                    <div className="right">
                        <div className="desktop-menu">
                            <ul>
                                {
                                    items.map(item => {
                                        if(Array.isArray(item.link)){
                                            return (
                                                <li className="sublink" >
                                                    <a>{ item.name } <FaAngleDown style={{ 'margin-left' : '.5rem' }}/></a>

                                                    <ul className="submenu">
                                                        {
                                                            item.link.map(subitem => (
                                                                <li><Link to={ subitem.link }>{ subitem.name }</Link></li>
                                                            ))
                                                        }
                                                    </ul>
                                                </li>
                                            )
                                        }else{
                                            return <li><Link to={ item.link }>{ item.name }</Link></li>
                                        }
                                    })
                                }
                            </ul>
                            <button className="btn btn-primary">Get A Quote</button>
                        </div>
                        <div className="mobile-menu">
                            <div className="hamburger" onClick={ toggleMenu }>
                                <span></span>
                                <span></span>
                                <span></span>
                            </div>
                            <ul className="mobile-menu-links">
                                {
                                    items.map(item => {
                                        if(Array.isArray(item.link)){
                                            return (
                                                <li className="sublink" >
                                                    <a>{ item.name } <FaAngleDown /></a>

                                                    <ul className="submenu">
                                                        {
                                                            item.link.map(subitem => (
                                                                <li><Link to={ subitem.link }>{ subitem.name }</Link></li>
                                                            ))
                                                        }
                                                    </ul>
                                                </li>
                                            )
                                        }else{
                                            return <li><Link to={ item.link }>{ item.name }</Link></li>
                                        }
                                    })
                                }
                            </ul>
                        </div>
                    </div>
            </div>
        </div>
    );
}

export default NavBar;

